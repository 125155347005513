<template>
    <div>
        <LocationsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Sales Managers</h1>
            <div class="col-md-6 col-sm-12">
              <p>
                <span class="territory">National Accounts</span><br>
                <b>Mike Larson</b><br>
                National Sales Manager<br>
                949.212.3850<br>
                <a href="mailto:mlarson@cdicurbs.com">mlarson@cdicurbs.com</a>
              </p>
            </div>
            <div class="col-md-6 col-sm-12">
            <p>
                <span class="territory">Charlotte</span><br>
                <b>Jack Ditter</b><br>
                Regional Sales Manager<br>
                763.742.2658<br>
                <a href="mailto:jditter@cdicurbs.com">jditter@cdicurbs.com</a>
            </p>
            </div>
            <div class="col-md-6 col-sm-12">
            <p>
                <span class="territory">Dallas</span><br>
                <b>Buddy Flaherty</b><br>
                Regional Sales Manager<br>
                952.334.2629<br>
                <a href="mailto:bflaherty@cdicurbs.com">bflaherty@cdicurbs.com</a>
            </p>
            </div>
            <div class="col-md-6 col-sm-12">
            <p>
                <span class="territory">Phoenix</span><br>
                <b>Les DeVatt</b><br>
                Sales Support Rep<br>
                803.374.0502<br>
                <a href="mailto:ldevatt@cdicurbs.com">ldevatt@cdicurbs.com</a>
            </p>
            </div>
            <div class="col-md-6 col-sm-12">
            <p>
                <span class="territory">Seattle</span><br>
                <b>Marc Porter</b><br>
                Regional Sales Manager<br>
                916.869.9328<br>
                <a href="mailto:mporter@cdicurbs.com">mporter@cdicurbs.com</a>
            </p>
            </div>
            <div class="col-md-6 col-sm-12">
            <p>
                <span class="territory">Minneapolis</span><br>
                <b>JT Timm</b><br>
                Regional Sales Manager<br>
                763.452.3921<br>
                <a href="mailto:jtimm@cdicurbs.com">jtimm@cdicurbs.com</a>
            </p>
            </div>

        </main>
    </div>
</template>

<script>
import LocationsNav from '@/components/content/LocationsNav.vue'
export default {
    name: "Territory",
    components: {
        LocationsNav,
    },
    created() {
        this.$appStore.setTitle(['Sales Manager']);
    },
}
</script>

<style scoped>
.territory{
    font-size: x-large;
    text-decoration: underline;
}
</style>